<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('REQUISITION')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- project -->
          <b-form-group :disabled="form.authorized"
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('PROJECT')}) | capitalize"
              label-for="project-input"
              :state="state('project')">
            <project-single-selector
                :disabled="form.authorized"
                @input="setProject"
                id="project-input"
                :state="state('project')"
                :errors="errors('project')"
                v-model="$v.form.project.$model">
            </project-single-selector>
          </b-form-group>
          <!-- project -->

          <!-- expenditure type -->
          <b-form-group :disabled="form.authorized"
              v-if="form.project"
              id="expenditure-type-form-group"
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('EXPENDITURE_TYPE')}) | capitalize"
              label-for="expenditure-type-input"
              :state="state('expenditureType')">
            <expenditure-type-single-selector :disabled="form.authorized" :project="form.project" class="w-100" v-model="$v.form.expenditureType.$model"
                                              :state="state('expenditureType')"></expenditure-type-single-selector>

            <b-form-invalid-feedback id="expenditure-type-input-feedback">
              <div v-for="error in errors('expenditureType')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_EXPENDITURE_TYPE_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- expenditure type -->

          <!-- donor -->
          <b-form-group :disabled="form.authorized"
              v-if="projectId"
              id="donor-form-group"
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('DONOR')}) | capitalize"
              label-for="donor-input"
              :state="state('donor')">
            <company-single-selector v-if="organizationId" :disabled="form.authorized" :projectId="projectId" @accounts="setAccounts" :allow-add="true" :type="'DONOR'"
                                     v-model="$v.form.donor.$model"
                                     :state="state('donor')"></company-single-selector>

            <b-form-invalid-feedback id="donor-input-feedback">
              <div v-for="error in errors('donor')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_DONOR_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- donor -->

          <!-- account -->
          <b-form-group
              v-if="this.form.donor"
              id="originBankAccount-form-group"
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('REQUISITION_ACCOUNT_LABEL')}) | capitalize"
              label-for="account-input"
              :state="state('donorAccount')">
            <b-select :disabled="form.authorized"  class="w-100" v-model="$v.form.donorAccount.$model" :state="state('donorAccount')">
              <b-select-option :value="account.code" :key="account.code"
                               v-for="account in accounts">{{ account.description }}
              </b-select-option>
            </b-select>
            <b-form-invalid-feedback id="account-input-feedback">
              <div v-for="error in errors('donorAccount')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_ACCOUNT_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- account -->

          <hr class="text-light"/>

          <!-- supplier -->
          <b-form-group :disabled="form.authorized"
              id="supplier-form-group"
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('SUPPLIER')}) | capitalize"
              label-for="supplier-input"
              :state="state('supplier')">
            <company-single-selector v-if="organizationId" :disabled="form.authorized" @rfc="setSupplierRFC" @accounts="setSupplierAccounts" :allow-add="true" :type="'SUPPLIER'"
                                     v-model="$v.form.supplier.$model"
                                     :state="state('supplier')"></company-single-selector>
            <b-form-invalid-feedback id="supplier-input-feedback">
              <div v-for="error in errors('supplier')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_SUPPLIER_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- supplier -->

          <!-- supplierAccount -->
          <b-form-group :disabled="form.authorized"
              v-if="this.form.supplier"
              id="supplierAccount-form-group"
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('REQUISITION_SUPPLIER_ACCOUNT_LABEL')}) | capitalize"
              label-for="supplierAccount-input"
              :state="state('supplierAccount')">
            <b-select class="w-100" :disabled="form.authorized"  v-model="$v.form.supplierAccount.$model" :state="state('supplierAccount')">
              <b-select-option :value="supplierAccount.code" :key="supplierAccount.code"
                               v-for="supplierAccount in supplierAccounts">{{ supplierAccount.description }}
              </b-select-option>
            </b-select>
            <b-form-invalid-feedback id="supplierAccount-input-feedback">
              <div v-for="error in errors('supplierAccount')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_SUPPLIER_ACCOUNT_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- supplierAccount -->

          <hr/>

          <!-- personal -->
          <b-form-group
              class="w-25 d-inline-block"
              id="personal-form-group"
              :label="$t('REQUISITION_PERSONAL_LABEL') | capitalize"
              label-for="personal-input"
              :state="state('personal')">
            <b-checkbox class="w-100 ml-1" :disabled="form.authorized" id="personal-input" type="text"
                        v-model="$v.form.personal.$model"
                        :state="state('personal')"
                        trim></b-checkbox>

            <b-form-invalid-feedback id="personal-input-feedback">
              <div v-for="error in errors('personal')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_PERSONAL_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- personal -->

          <!-- personal account text input -->
          <b-form-group :disabled="form.authorized"
              v-if="form.personal"
              id="personal-account-form-group"
              :label="$t('REQUISITION_PERSONAL_ACCOUNT_LABEL') | capitalize"
              label-for="personal-account-input"
              :state="state('personalAccount')">
            <b-input class="w-100 ml-1" id="personal-account-input" type="text"
                     :disabled="form.authorized"
                     v-model="$v.form.personalAccount.$model"
                     :state="state('personalAccount')"
                     trim></b-input>

            <b-form-invalid-feedback id="personal-account-input-feedback">
              <div v-for="error in errors('personalAccount')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_PERSONAL_ACCOUNT_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- personal account text input -->

          <div v-if="project.budgetDetails && hasSupplier">
            <div class="w-100">
              <div class="w-75 d-inline-block"><h2>{{ $t('REQUISITION_INVOICES_LABEL') | capitalize }}</h2></div>
              <div class="w-25 d-inline-block text-right">
                <b-button :disabled="form.authorized || uploadingXml" variant="primary" @click="triggerUploadDialog('xml')">
                  <b-icon-circle-fill animation="throb" v-if="uploadingXml"></b-icon-circle-fill>
                  <b-icon-cloud-upload v-else class="mr-1"></b-icon-cloud-upload>
                  {{$t('XML_UPLOAD_LABEL') | capitalize}}
                </b-button>
                <input @change="uploadXml" type="file" class="d-none" ref="xml"/>
                <input @change="uploadPdf" type="file" class="d-none" ref="pdf"/>
              </div>
            </div>

            <div v-if="!id">
              <div class="w-100">
                {{$t('BUDGET_FOR') | capitalize}}: {{$t(budget.description)}} ${{budget.balance | currency}} {{budget.currency}}
              </div>
              <div class="w-100">
                {{$t('BUDGET_SPENT') | capitalize}}: ${{spent | currency}} {{budget.currency}}
              </div>
              <div class="w-100">
                {{$t('BUDGET_AFTER') | capitalize}}: ${{(budget.balance - spent) | currency}} {{budget.currency}}
              </div>
            </div>

            <hr/>
            <div v-if="uploadingPdf" class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong
                  class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('INVOICES_LABEL')})  | capitalize }}</strong>
            </div>
            <table v-else class="w-100 my-2">
              <thead>
              <tr>
                <th>{{ $t('REQUISITION_INVOICE_DATE_LABEL') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_NUMBER_LABEL') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_IVA_LABEL') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_RET_IVA_LABEL') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_RET_ISR_LABEL') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_SUBTOTAL_LABEL') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_TOTAL_LABEL') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_OPTIONS_XML') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_OPTIONS_PDF') | capitalize }}</th>
                <th>{{ $t('REQUISITION_INVOICE_OPTIONS_LABEL') | capitalize }}</th>
              </tr>
              </thead>
              <tbody>
              <tr :key="index" v-for="(invoice, index) in form.invoices">
                <td>
                  {{invoice.date | date}}
                </td>
                <td>
                  {{invoice.number}}
                </td>
                <td>
                  {{invoice.iva | currency}}
                </td>
                <td>
                  {{invoice.retIva | currency}}
                </td>
                <td>
                  {{invoice.retIsr | currency}}
                </td>
                <td>
                  {{invoice.subtotal | currency}}
                </td>
                <td>
                  {{invoice.total | currency}}
                </td>
                <td>
                  <b-icon-check-circle class="text-success" v-if="invoice.xml"></b-icon-check-circle>
                  <b-icon-x-circle class="text-danger" v-else></b-icon-x-circle>
                </td>
                <td>
                  <b-icon-check-circle class="text-success" v-if="invoice.pdf"></b-icon-check-circle>
                  <b-icon-x-circle class="text-danger" v-else></b-icon-x-circle>
                </td>
                <td>
                  <b-button class="mx-3 mb-3" :disabled="form.authorized" variant="danger" @click="removeInvoice(index)">
                    <b-icon-x-circle></b-icon-x-circle>
                  </b-button>
                  <b-button class="mb-3" :disabled="form.authorized" variant="primary" @click="triggerUploadDialog('pdf', index)">
                    <b-icon-cloud-upload></b-icon-cloud-upload>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>

            <b-modal size="xl" hide-footer :title="$t('REQUISITION_INVOICES_LABEL') | capitalize" id="invoice-modal">
              <invoice-form :sender-r-f-c="supplierRFC" :receptor-r-f-c="organizationRFC" :upload-dir="form.uploadDirectory" @submitted="addInvoice"></invoice-form>
            </b-modal>
          </div>
          <div v-else>
            <h3>{{$t('CANNOT_LOAD_INVOICES_YET') | capitalize}}</h3>
          </div>


          <!-- paymentDate input -->
          <b-form-group :disabled="form.authorized"
              id="paymentDate-form-group"
              :label="$t('REQUISITION_PAYMENT_DATE_LABEL') | capitalize"
              label-for="paymentDate-input"
              :state="state('paymentDate')">
            <b-form-datepicker
                :disabled="form.authorized"
                v-model="$v.form.paymentDate.$model"
                locale="es-MX"
                :state="state('paymentDate')"
                button-variant="dark"></b-form-datepicker>

            <b-form-invalid-feedback id="paymentDate-input-feedback">
              <div v-for="error in errors('paymentDate')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_PAYMENT_DATE_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- paymentDate input -->

          <!-- concept text input -->
          <b-form-group :disabled="form.authorized"
              id="concept-form-group"
              :label="$t('REQUISITION_CONCEPT_LABEL') | capitalize"
              label-for="concept-input"
              :state="state('concept')">
            <b-textarea class="w-100 ml-1" id="concept-input" type="text"
                        v-model="$v.form.concept.$model"
                        :state="state('concept')"
                        :disabled="form.authorized"
                        trim></b-textarea>

            <b-form-invalid-feedback id="concept-input-feedback">
              <div v-for="error in errors('concept')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_CONCEPT_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- concept text input -->

          <!-- observations text input -->
          <b-form-group
              id="observations-form-group"
              :label="$t('REQUISITION_OBSERVATIONS_LABEL') | capitalize"
              label-for="observations-input"
              :state="state('observations')">
            <b-textarea :disabled="form.authorized"  class="w-100 ml-1" id="observations-input" type="text"
                        v-model="$v.form.observations.$model"
                        :state="state('observations')"
                        trim></b-textarea>

            <b-form-invalid-feedback id="observations-input-feedback">
              <div v-for="error in errors('observations')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_OBSERVATIONS_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- observations text input -->

          <b-button class="mt-2" type="submit" :disabled="saving || form.authorized" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
    {{organizationRFC}}
    {{supplierRFC}}
  </div>
</template>

<script>
import {maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
import {DateFilter, Form, NumberFilter, ProcessWithLoadingAndMessage} from "@/mixins";
import Requisition from "@/requisition";
import Constants from "@/constants";
import ProjectSingleSelector from "@/project/ProjectSingleSelector"
import ExpenditureTypeSingleSelector from "@/expenditureType/ExpenditureTypeSingleSelector";
import CompanySingleSelector from "@/company/CompanySingleSelector";
import convert from 'xml-js';
import {v4} from 'uuid'
import InvoiceForm from '@/requisition/InvoiceForm'
import Project from "@/project";
import Files from "@/files";

export default {
  name: "RequisitionForm",
  components: {InvoiceForm, CompanySingleSelector, ExpenditureTypeSingleSelector, ProjectSingleSelector},
  mixins: [Form, ProcessWithLoadingAndMessage, NumberFilter, DateFilter],
  methods: {
    async uploadXml(ev) {
      try {
        this.uploadingXml = true;
        const res = await Files.uploadXml(this.form.uploadDirectory, 'xml', ev.target.files[0]);
        if(res.data.error) {
          this.sendMessage(res.data.error, {uuid: res.data.uuid});
        } else {
          const receptorRFC = res.data.receptorRFC;
          const senderRFC = res.data.senderRFC;
          if (this.organizationRFC.toLowerCase() === receptorRFC.toLowerCase() && this.supplierRFC.toLowerCase() === senderRFC.toLowerCase()) {
            const existsOnBackend = await Requisition.invoiceExists(res.data.uuid);
            const existsCurrent = this.form.invoices.map(x => x.uuid).indexOf(res.data.uuid) !== -1;
            console.log('exists on backend?')
            console.log(existsOnBackend);
            console.log('exists current?')
            console.log(existsCurrent);
            if(existsOnBackend) {
              console.log('exists on backend')
              this.sendError('INVOICE_UUID_REPEATED_SERVER', {filename: res.data.fileName});
            }
            else if(existsCurrent) {
              console.log('exists current ')
              this.sendError('INVOICE_UUID_REPEATED_CURRENT', {filename: res.data.fileName});
            }
            else {
              res.data.xml = res.data.fileName
              delete res.data.fileName;
              this.form.invoices.push(res.data);
              this.form.xml = res.data.fileName;
              this.sendMessage('INVOICE_XML_UPLOADED', {filename: res.data.fileName});
            }
          } else if (this.organizationRFC.toLowerCase() !== receptorRFC.toLowerCase()) {
            this.sendError('ERROR_UPLOADING_INVOICE_XML_RECEPTOR_RFC', {});
          } else {
            this.sendError('ERROR_UPLOADING_INVOICE_XML_SENDER_RFC', {});
          }
        }
      } catch (e) {
        this.sendError('ERROR_UPLOADING_INVOICE_XML', {}, e);
      } finally {
        this.uploadingXml = false;
      }
    },
    async uploadPdf(ev) {
      try {
        this.uploadingPdf = true;
        const res = await Files.upload(this.form.uploadDirectory, 'pdf', ev.target.files[0]);
        this.$v.form.invoices.$model[this.pdfIdx].pdf = res.data.fileName;
        this.sendMessage('INVOICE_PDF_UPLOADED', {filename: res.data.fileName});
      } catch (e) {
        this.sendError('ERROR_UPLOADING_INVOICE_PDF', {}, e);
      } finally {
        this.uploadingPdf = false;
      }
    },
    triggerUploadDialog(field, idx) {
      this.pdfIdx = idx;
      this.$refs[field].click();
    },
    async setProject(val) {
      const split = val.split('/');
      this.projectId = split[split.length - 1];
      try {
        this.loadingBudget = true;
        const resp = await Project.findById(this.projectId);
        this.project = resp.data;
        this.organizationRFC = resp.data._embedded.organization.rfc;
      } catch (e) {
        this.sendError('ERROR_LOADING_PROJECT', {}, e);
      } finally {
        this.loadingBudget = false;
      }
    },
    beforeSave(form) {
      form.$touch();
      if (form.$invalid) {
        if (Constants.DEBUG) {
          console.log(form);
        }
        throw new Error('Form is invalid');
      }
    },
    showInvoiceModal() {
      this.$bvModal.show('invoice-modal')
    },
    setAccounts(ev) {
      this.accounts = ev;
    },
    setSupplierAccounts(ev) {
      this.supplierAccounts = ev.filter(x => x.organizationId === this.organizationId)
    },
    addInvoice(data) {
      let sum = this.calcTotal();
      sum += data.total;
      if(sum > this.budget.balance) {
        this.sendError('CANNOT_ADD_BUDGET_EXCEEDED', {by: sum - this.budget.amount});
      }
      this.form.invoices.push(data);
      this.$bvModal.hide('invoice-modal')
    },
    removeInvoice(idx) {
      this.form.invoices.splice(idx, 1);
      this.calcTotal();
    },
    calcTotal() {
      let sum = 0;
      this.form.invoices.forEach(x => sum += x.total);
      return sum;
    },
    setSupplierRFC(ev) {
      this.supplierRFC = ev;
    },
    setOrganizationRFC(ev) {
      this.organizationRFC = ev;
    },
    async doSave(id, data) {
      return id ? await Requisition.update(id, data) : await Requisition.create(data);
    },
    readXml(xml) {
      convert.xml2json(xml, {compact: true, spaces: 4});
    },
    getData() {
      this.loadingFormData = true;
      Requisition.findById(this.id)
          .then(resp => {
            console.log('Requisition')
            console.log(resp.data)
            this.form.authorized = resp.data.authorized;
            this.form.concept = resp.data.concept;
            this.form.uploadDirectory = resp.data.uploadDirectory;
            this.form.observations = resp.data.observations;
            this.form.paymentDate = resp.data.paymentDate;
            this.form.type = resp.data.type;
            this.form.account = resp.data.account;
            this.$v.form.total.$model = this.calcTotal();
            this.form.project = resp.data._embedded.project._links.self.href.replace('{?projection}', '');
            this.setProject(this.form.project);
            this.form.expenditureType = resp.data._embedded.expenditureType._links.self.href.replace('{?projection}', '');
            this.form.supplier = resp.data._embedded.supplier._links.self.href.replace('{?projection}', '');
            this.form.donor = resp.data._embedded.donor._links.self.href.replace('{?projection}', '');
            this.form.supplierAccount = resp.data.supplierAccount;
            this.form.donorAccount = resp.data.donorAccount;
            this.form.personal = resp.data.personal;
            this.form.personalAccount = resp.data.personalAccount;
            this.form.invoices = resp.data.invoices;

            this.setSupplierRFC(resp.data._embedded.supplier.rfc);
            this.setSupplierAccounts(resp.data._embedded.supplier.accounts)
            this.setAccounts(resp.data._embedded.donor.accounts)
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  computed: {
    budget() {
      const expenditureType = this.form.expenditureType;
      if(expenditureType !== "" && this.project && this.project.budgetDetails){
        const budget = this.project.budgetDetails.find(x => x._links.expenditureType.href.replace('{?projection}', '') === expenditureType);
        if(budget) {
          return budget;
        }
      }
      return {description: 'NO_BUDGET_FOUND', amount: 0, currency: '', spent: 0, balance: 0};
    },
    spent() {
      let sum = 0;
      this.form.invoices.forEach(x => sum += x.total);
      return sum;
    },
    hasSupplier() {
      return !!this.form.supplier;
    },
    organizationId() {
      return this.project && this.project._embedded ? this.project._embedded.organization.id : undefined;
    }
  },
  data() {
    return {
      pdfIdx: 0,
      uploadingXml: false,
      uploadingPdf: false,
      supplierRFC: '',
      organizationRFC: '',
      loadingFormData: false,
      originals: {},
      project: {},
      loadingBudget: false,
      projectId: undefined,
      form: {
        authorized: false,
        embeddedInvoices: [],
        concept: null,
        observations: null,
        paymentDate: null,
        expenditureType: null,
        donor: null,
        donorAccount: null,
        invoices: [],
        project: null,
        supplier: null,
        supplierAccount: null,
        personal: null,
        personalAccount: null,
        uploadDirectory: v4()
      },
      accounts: [],
      supplierAccounts: [],
      editPage: 'REQUISITION_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      concept: {maxLength: maxLength(500)},
      observations: {maxLength: maxLength(500)},
      paymentDate: {required},
      expenditureType: {required},
      donorAccount: {required},
      embeddedInvoices: {},
      invoices: {
        required, minLength: minLength(1), $each: {
          date: {required},
          number: {required},
          iva: {minValue: minValue(0)},
          retIva: {minValue: minValue(0)},
          retIsr: {minValue: minValue(0)},
          subtotal: {minValue: minValue(0)},
          total: {minValue: minValue(0)},
          exchangeRate: {minValue: minValue(0)},
          currency: {required},
          xml: {},
          pdf: {},
        }
      },
      total: {},
      project: {required},
      donor: {required},
      supplier: {required},
      supplierAccount: {required},
      personal: {},
      personalAccount: {},
    }
  }
}
</script>

<style scoped>

</style>
