<template>
  <b-form @submit.prevent="sendInvoice">
    <app-messages></app-messages>
    <b-button :disabled="uploadingXml" class="mb-2" @click="triggerUploadDialog('xml')" variant="info">
      <b-icon-circle-fill animation="throb" v-if="uploadingXml"></b-icon-circle-fill>
      <b-icon-cloud-upload v-else></b-icon-cloud-upload>
      {{ $t('REQUISITION_INVOICE_UPLOAD_XML') | capitalize }}
    </b-button>
    <input @change="uploadXml" type="file" class="d-none" ref="xml"/>

    <b-button :disabled="uploadingPdf" class="ml-1 mb-2" @click="triggerUploadDialog('pdf')" variant="info">
      <b-icon-circle-fill animation="throb" v-if="uploadingPdf"></b-icon-circle-fill>
      <b-icon-cloud-upload v-else></b-icon-cloud-upload>
      {{ $t('REQUISITION_INVOICE_UPLOAD_PDF') | capitalize }}
    </b-button>

    <input @change="uploadPdf" type="file" class="d-none" ref="pdf"/>

    <b-form-group
        id="date-form-group"
        :label="$t('REQUISITION_INVOICE_DATE_LABEL') | capitalize"
        label-for="date-input"
        locale="es-MX"
        :state="state('date')">
      <b-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    class="w-100 ml-1" id="date-input" type="text"
                    v-model="$v.form.date.$model"
                    :state="state('date')"
                    trim></b-datepicker>

      <b-form-invalid-feedback id="date-input-feedback">
        <div v-for="error in errors('date')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_DATE_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
        id="number-form-group"
        :label="$t('REQUISITION_INVOICE_NUMBER_LABEL') | capitalize"
        label-for="number-input"
        :state="state('number')">
      <b-input class="w-100 ml-1" id="number-input" type="text"
               v-model="$v.form.number.$model"
               :state="state('number')"
               trim></b-input>

      <b-form-invalid-feedback id="number-input-feedback">
        <div v-for="error in errors('number')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_NUMBER_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
        id="iva-form-group"
        :label="$t('REQUISITION_INVOICE_IVA_LABEL') | capitalize"
        label-for="iva-input"
        :state="state('iva')">
      <b-input class="w-100 ml-1" id="iva-input" type="text"
               v-model="$v.form.iva.$model"
               :state="state('iva')"
               trim></b-input>

      <b-form-invalid-feedback id="iva-input-feedback">
        <div v-for="error in errors('iva')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_IVA_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
        id="ret-iva-form-group"
        :label="$t('REQUISITION_INVOICE_RET_IVA_LABEL') | capitalize"
        label-for="ret-iva-input"
        :state="state('retIva')">
      <b-input @blur="calcTotal" class="w-100 ml-1" id="ret-iva-input" type="text"
               v-model="$v.form.retIva.$model"
               :state="state('retIva')"
               trim></b-input>

      <b-form-invalid-feedback id="ret-iva-input-feedback">
        <div v-for="error in errors('retIva')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_RET_IVA_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
        id="ret-isr-form-group"
        :label="$t('REQUISITION_INVOICE_RET_ISR_LABEL') | capitalize"
        label-for="ret-isr-input"
        :state="state('retIsr')">
      <b-input @blur="calcTotal" class="w-100 ml-1" id="ret-isr-input" type="text"
               v-model="$v.form.retIsr.$model"
               :state="state('retIsr')"
               trim></b-input>

      <b-form-invalid-feedback id="ret-isr-input-feedback">
        <div v-for="error in errors('retIsr')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_RET_ISR_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
        id="subtotal-form-group"
        label-for="subtotal-input"
        :label="$t('REQUISITION_INVOICE_SUBTOTAL_LABEL') | capitalize"
        :state="state('subtotal')">
      <b-input @blur="calcTotal" class="w-100 ml-1" id="subtotal-input" type="text"
               v-model="$v.form.subtotal.$model"
               :state="state('subtotal')"
               trim></b-input>

      <b-form-invalid-feedback id="subtotal-input-feedback">
        <div v-for="error in errors('subtotal')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_SUBTOTAL_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
        id="total-form-group"
        label-for="total-input"
        :label="$t('REQUISITION_INVOICE_TOTAL_LABEL') | capitalize"
        :state="state('total')">
      <b-input @blur="calcTotal" class="w-100 ml-1" id="total-input" type="text"
               v-model="$v.form.total.$model"
               :state="state('total')"
               trim></b-input>

      <b-form-invalid-feedback id="total-input-feedback">
        <div v-for="error in errors('total')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_TOTAL_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
        id="currency-form-group"
        label-for="currency-input"
        :label="$t('REQUISITION_INVOICE_CURRENCY_LABEL') | capitalize"
        :state="state('currency')">
      <b-input @blur="calcTotal" class="w-100 ml-1" id="currency-input" type="text"
               v-model="$v.form.currency.$model"
               :state="state('currency')"
               trim></b-input>

      <b-form-invalid-feedback id="currency-input-feedback">
        <div v-for="error in errors('currency')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_CURRENCY_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
        id="exchange-rate-form-group"
        label-for="exchange-rate-input"
        :label="$t('REQUISITION_INVOICE_EXCHANGE_RATE_LABEL') | capitalize"
        :state="state('exchangeRate')">
      <b-input @blur="calcTotal" class="w-100 ml-1" id="exchange-rate-input" type="text"
               v-model="$v.form.exchangeRate.$model"
               :state="state('exchangeRate')"
               trim></b-input>

      <b-form-invalid-feedback id="exchange-rate-input-feedback">
        <div v-for="error in errors('exchangeRate')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('REQUISITION_INVOICE_EXCHANGE_RATE_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
      <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
      <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
      <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
    </b-button>
  </b-form>
</template>

<script>
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import {decimal, minLength, minValue, required} from "vuelidate/lib/validators";
import Files from "@/files";
import AppMessages from "@/layout/AppMessages";

export default {
  name: "InvoiceForm",
  components: {AppMessages},
  mixins: [Form, ProcessWithLoadingAndMessage],
  props: ['uploadDir', 'senderRFC', 'receptorRFC'],
  methods: {
    sendInvoice() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submitted', this.form);
      }
    },
    calcTotal() {
      this.form.total = (+this.form.subtotal + +this.form.iva) - this.form.retIsr - this.form.retIva;
    },
    async uploadXml(ev) {
      try {
        this.uploadingXml = true;
        const res = await Files.uploadXml(this.uploadDir, 'pdf', ev.target.files[0]);
        if(res.data.error) {
          this.sendMessage(res.data.error, {uuid: res.data.uuid});
        } else {
          const receptorRFC = res.data.receptorRFC;
          const senderRFC = res.data.senderRFC;
          if (this.receptorRFC.toLowerCase() === receptorRFC.toLowerCase() && this.senderRFC.toLowerCase() === senderRFC.toLowerCase()) {
            this.form = res.data;
            this.form.xml = res.data.fileName;
            this.sendMessage('INVOICE_XML_UPLOADED', {filename: res.data.fileName});
          } else if (this.receptorRFC.toLowerCase() !== receptorRFC.toLowerCase()) {
            this.sendError('ERROR_UPLOADING_INVOICE_XML_RECEPTOR_RFC', {});
          } else {
            this.sendError('ERROR_UPLOADING_INVOICE_XML_SENDER_RFC', {});
          }
        }
      } catch (e) {
        this.sendError('ERROR_UPLOADING_INVOICE_XML', {}, e);
      } finally {
        this.uploadingXml = false;
      }
    },
    async uploadPdf(ev) {
      try {
        this.uploadingPdf = true;
        const res = await Files.upload(this.uploadDir, 'pdf', ev.target.files[0]);
        this.form.pdf = res.data.fileName;
        this.sendMessage('INVOICE_PDF_UPLOADED', {filename: res.data.fileName});
      } catch (e) {
        this.sendError('ERROR_UPLOADING_INVOICE_PDF', {}, e);
      } finally {
        this.uploadingPdf = false;
      }
    },
    triggerUploadDialog(field) {
      this.$refs[field].click();
    }
  },
  data() {
    return {
      uploadingXml: false,
      uploadingPdf: false,
      form: {
        date: new Date(),
        number: null,
        iva: 0,
        retIsr: 0,
        retIva: 0,
        subtotal: 0,
        total: 0,
        exchangeRate: 1,
        currency: 'MXN',
        pdf: '',
        xml: '',
      }
    }
  },
  validations() {
    return this.rfc ? {
      form: {
        date: {required},
        number: {required},
        iva: {decimal, required, minValue: minValue(0)},
        retIsr: {decimal, required, minValue: minValue(0)},
        retIva: {decimal, required, minValue: minValue(0)},
        subtotal: {decimal, required, minValue: minValue(0)},
        total: {decimal, required, minValue: minValue(0)},
        exchangeRate: {minValue: minValue(1)},
        currency: {required, minLength: minLength(3)},
      }
    } : {
      form: {
        date: {required},
        number: {required},
        iva: {decimal, required, minValue: minValue(0)},
        retIsr: {decimal, required, minValue: minValue(0)},
        retIva: {decimal, required, minValue: minValue(0)},
        subtotal: {decimal, required, minValue: minValue(0)},
        total: {decimal, required, minValue: minValue(0)},
        exchangeRate: {minValue: minValue(1)},
        currency: {required, minLength: minLength(3)},
      }
    }
  }
}
</script>

<style scoped>

</style>